import './App.css';
import background from "./img/ea71bb_27587be15bf449e3933a012334862e16~mv2_d_3973_2235_s_2.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <section className="welcome">
        <h1>Klímaszerviz</h1>
        <h2>
          Klímaszerelés, klímatelepítés,<br />klímatisztítás, klímakarbantartás
        </h2>
        <div className="grad1"></div>
      </section>
      <section className="info">
        <h2 className="infotxt">Hűtő- és fűtő klímaberendezések szervizelése, telepítése, karbantartása, javítása</h2>
      </section>
      <section className="contact">
        <FontAwesomeIcon icon={faPhone} className="phone-icon" />
        <span className="phonenr">+36 30 559 8349</span>
      </section>
    </div>
  );
}

export default App;
